import { trackPromise } from "react-promise-tracker";
import { useMutation } from "react-query";
import accountService, { type UpdateEmailResponse } from "../AccountService";
import AccountServiceError from "../AccountServiceError";

interface UpdateEmailVariables {
    password: string;
    newEmail: string;
}

const useUpdateEmail = () => {
    const { isLoading, isSuccess, isError, mutateAsync, reset } = useMutation<
        UpdateEmailResponse,
        AccountServiceError,
        UpdateEmailVariables
    >((variables) => {
        const { password, newEmail } = variables;

        return trackPromise(accountService.updateEmail(password, newEmail));
    });

    const updateEmail = (password: string, newEmail: string) => mutateAsync({ password, newEmail });

    return {
        updateEmail,
        isLoading,
        isSuccess,
        isError,
        clearUpdateEmailState: reset,
    };
};

export default useUpdateEmail;

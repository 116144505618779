import is from "@sindresorhus/is";
import ow from "ow";
import languages from "../constants/languages";
import { LanguageKind as GrpcLanguageKind } from "../grpc/account_common_pb";
import { AccountLanguageKind as LanguageKind } from "../models/accountLanguageKind";

/**
 * Map the LanguageKind enum from AccountService to the corresponding language object.
 * @param {null|number} languageKind - The LanguageKind enum from AccountService.
 * @returns {null|object} The corresponding language object.
 */
function mapLanguageKindEnumToLanguage(languageKind) {
    if (is.nullOrUndefined(languageKind)) {
        return null;
    }

    ow(languageKind, ow.number);
    switch (languageKind) {
        case GrpcLanguageKind.ENGLISH:
            return languages.english;
        case GrpcLanguageKind.SPANISH:
            return languages.spanish;
    }

    return null;
}

/**
 * Map the language object to the corresponding LanguageKind from AccountService.
 * @param {null|object} language - The language object.
 * @returns {null|number} The LanguageKind enum from AccountService.
 */
function mapLanguageToLanguageKindEnum(language) {
    if (is.nullOrUndefined(language)) {
        return null;
    }

    ow(language.key, ow.string);
    switch (language.key) {
        case LanguageKind.English:
            return GrpcLanguageKind.ENGLISH;
        case LanguageKind.Spanish:
            return GrpcLanguageKind.SPANISH;
    }

    return null;
}

export { mapLanguageKindEnumToLanguage, mapLanguageToLanguageKindEnum };

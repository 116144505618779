import { createContext } from "react";

const noOp = () => {};

/**
 * @typedef EmailVerificationContext
 * @property {string} verificationToken - The email verification token.
 * @property {() => void} clearVerificationToken - Clears the email verification token.
 */

/** @type {EmailVerificationContext} */
const initialContext = {
    verificationToken: null,
    clearVerificationToken: noOp,
};

const EmailVerificationContext = createContext(initialContext);

export default EmailVerificationContext;

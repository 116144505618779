import is from "@sindresorhus/is";
import ow from "ow";
import phoneFormatter from "phone-formatter";
/** @typedef {import("../constants/fullName").FullName} FullName */

/**
 * Formats a string, with optional delimitation
 * @param {string|null} optionalString - The string to format
 * @param {string} delimiter - An optional delimiter that is prefixed in front of the string
 * @returns {string} A formatted string
 * @private
 */
function _getOptionalString(optionalString, delimiter = "") {
    ow(optionalString, ow.any(ow.null, ow.optional.string));
    ow(delimiter, ow.string);

    return is.nullOrUndefined(optionalString) || is.emptyStringOrWhitespace(optionalString)
        ? ""
        : `${delimiter}${optionalString}`;
}

/**
 * @typedef FormatAddressInput
 * @property {string | null} [street] - street
 * @property {string | null} [street2] - street2
 * @property {string | null} [city] - city
 * @property {string | null} [state] - state
 * @property {string | null} [zipCode] - zipCode
 */

/**
 * Format an address.
 * @param {FormatAddressInput | null} [address] - address
 * @param {boolean} multiline - Whether address should be formatted using multiple lines.
 * @returns {string} - formatted address string
 */
const formatAddress = (address, multiline = true) => {
    ow(
        address,
        ow.any(
            ow.object.partialShape({
                street: ow.any(ow.string, ow.nullOrUndefined),
                street2: ow.any(ow.string, ow.nullOrUndefined),
                city: ow.any(ow.string, ow.nullOrUndefined),
                state: ow.any(ow.string, ow.nullOrUndefined),
                zipCode: ow.any(ow.string, ow.nullOrUndefined),
            }),
            ow.nullOrUndefined
        )
    );

    if (
        is.nullOrUndefined(address) ||
        !is.nonEmptyStringAndNotWhitespace(address.street) ||
        !is.nonEmptyStringAndNotWhitespace(address.city) ||
        !is.nonEmptyStringAndNotWhitespace(address.state) ||
        !is.nonEmptyStringAndNotWhitespace(address.zipCode)
    ) {
        return "";
    }

    const formattedStreet2 = _getOptionalString(address.street2, ", ");
    return `${address.street}${formattedStreet2}${multiline ? "\n" : ", "}${address.city}, ${
        address.state
    } ${address.zipCode}`;
};

/**
 * Format a list of names
 * @param {string[]} names - names to format
 * @returns {string} formatted names
 */
const formatNames = (names = []) => {
    ow(names, ow.array.ofType(ow.string));
    return is.emptyArray(names) ? "" : names.join(", ");
};

/**
 * Format a value into a phone number and retain the extension if present.
 * @param {string} phoneNumber - phone number possibly including an extension
 * @returns {string} - formatted phone number with extension if it was included
 */
const formatPhoneNumber = (phoneNumber) => {
    ow(phoneNumber, ow.any(ow.nullOrUndefined, ow.string));

    if (is.nullOrUndefined(phoneNumber)) {
        return "";
    }

    const [base, extension] = phoneNumber.split("ext.", 2);

    // Remove all non-digit characters from the base phone number
    const digits = base.replace(/\D/g, "");

    if (digits.length < 10) {
        return "";
    }

    let formatted = phoneFormatter.format(digits, "(NNN) NNN-NNNN");

    if (extension) {
        formatted += ` ext.${extension.trim()}`;
    }

    return formatted;
};

/**
 * Format a value into a currency. (currently only supports english and USD)
 * @param {number | null} value - monetary value
 * @param {string} currencyKind - type of currency (e.g. USD)
 * @param {object} [options = {}] - Config object for the Intl.NumberFormat constructor (see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat#parameters)
 * @returns {string} - formatted value
 */
const formatCurrency = (value, currencyKind, options = {}) => {
    ow(value, ow.any(ow.null, ow.optional.number));
    ow(currencyKind, ow.string.nonEmpty);
    ow(options, ow.object);
    if (!Number.isFinite(value)) {
        return "";
    }

    const formatter = new Intl.NumberFormat("en", {
        style: "currency",
        currency: currencyKind,
        ...options,
    });
    return formatter.format(value);
};

/**
 * @typedef FormatNameInput
 * @property {string | null} [firstName] - first name
 * @property {string | null} [middleName] - middle name
 * @property {string | null} [lastName] - last name
 * @property {string | null} [suffix] - suffix
 */

/**
 * Format a name
 * @param {FormatNameInput|null} [name] - name to format
 * @returns {string} - formatted name
 */
const formatName = (name) => {
    ow(
        name,
        ow.any(
            ow.null,
            ow.optional.object.partialShape({
                firstName: ow.any(ow.null, ow.optional.string),
                middleName: ow.any(ow.null, ow.optional.string),
                lastName: ow.any(ow.null, ow.optional.string),
                suffix: ow.any(ow.null, ow.optional.string),
            })
        )
    );

    return is.nullOrUndefined(name)
        ? ""
        : `${_getOptionalString(name.firstName)}${_getOptionalString(
              name.middleName,
              " "
          )}${_getOptionalString(name.lastName, " ")}${_getOptionalString(name.suffix, " ")}`;
};

/**
 * Format participant a name
 * @param {{ organizationName?: string} & FullName|null} [name] - name to format
 * @returns {string} - formatted name
 */
const formatParticipantName = (name) => {
    ow(
        name,
        ow.any(
            ow.null,
            ow.optional.object.partialShape({
                firstName: ow.string,
                middleName: ow.any(ow.null, ow.optional.string),
                lastName: ow.any(ow.null, ow.optional.string),
                suffix: ow.any(ow.null, ow.optional.string),
            }),
            ow.optional.object.partialShape({
                organizationName: ow.optional.string,
            })
        )
    );

    return is.nonEmptyString(name.organizationName) ? name.organizationName : formatName(name);
};

export {
    formatAddress,
    formatPhoneNumber,
    formatCurrency,
    formatName,
    formatNames,
    formatParticipantName,
};

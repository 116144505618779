import React from "react";
import is from "@sindresorhus/is";
import currency from "currency.js";
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";
import { differenceInYears, format, isAfter, isBefore, isEqual, parse } from "date-fns/esm";
import { enUS } from "date-fns/esm/locale";
import FileSaver from "file-saver";
import ow from "ow";
import { Location } from "react-router-dom";
import { format as reactStringFormat } from "react-string-format";
import currencyKinds from "../constants/currencyKinds";
import { Address } from "../models/addresses";
import { Participant } from "../models/participant";
import { formatCurrency, formatName } from "./formatterUtils";
import { validateDateString, validateZipCode } from "./validationUtils";

/**
 * getSubsectionKeyFromPath - Get subsection key associated with the path provided
 * @param routes - routes
 * @param pathName - pathName
 * @returns - Subsection
 */
function getSubsectionKeyFromPath(routes: { [key: string]: string }, pathName: string) {
    ow(routes, ow.object.nonEmpty);
    ow(pathName, ow.string.nonEmpty);

    return Object.keys(routes).find((key) => routes[key] === pathName);
}

/**
 * getLastUrlPathSegment - Get the path from the location object without the slash
 * @param location - The location object
 * @returns - Pathname
 */
function getLastUrlPathSegment(location: Location) {
    ow(location, ow.object.partialShape({ pathname: ow.string }));

    return location.pathname.slice(1);
}

/**
 * isDateEqual - Given two dates, return a boolean indicating if they are equal
 * @param date1 - The date to test
 * @param date2 - The date to compare against
 * @returns - Boolean indicating if date1 is equal to date2
 */
function isDateEqual(date1: Date | number, date2: Date | number) {
    ow(date1, ow.any(ow.date, ow.number));
    ow(date2, ow.any(ow.date, ow.number));

    return isEqual(date1, date2);
}

/**
 * isDateBefore - Given two dates, return a boolean indicating the first came before the second date
 * @param date1 - The date to test
 * @param date2 - The date to compare against
 * @returns - Boolean indicating if date1 < date2
 */
function isDateBefore(date1: Date | number, date2: Date | number) {
    ow(date1, ow.any(ow.date, ow.number));
    ow(date2, ow.any(ow.date, ow.number));

    return isBefore(date1, date2);
}

/**
 * isDateAfter - Given two dates, return a boolean indicating the first came after the second date
 * @param date1 - The date to test
 * @param date2 - The date to compare against
 * @returns - Boolean indicating if date1 > date2
 */
function isDateAfter(date1: Date | number, date2: Date | number) {
    ow(date1, ow.any(ow.date, ow.number));
    ow(date2, ow.any(ow.date, ow.number));

    return isAfter(date1, date2);
}

/**
 * isDateLaterThanOrEqualToToday - Checks if a given date is today or later.
 * @param date - The date to test
 * @returns - Boolean indicating if the date is today or later
 */
function isDateLaterThanOrEqualToToday(date: Date | number) {
    ow(date, ow.any(ow.date, ow.number));

    const todayDate = new Date();
    todayDate.setHours(0, 0, 0, 0);

    return !isDateBefore(date, todayDate) || isDateEqual(date, todayDate);
}

/**
 * getNowDateUTC - Get the current Date in UTC
 * @returns - Current Time as a Date in UTC
 */
const getNowDateUTC = () => new Date(Date.now());

/**
 * getDateUTC - Given individual date parameters, return a UTC based Date object
 * @param year - Year
 * @param month - Month
 * @param day - Day
 * @param hours - Hours
 * @param minutes - Minutes
 * @param seconds - Seconds
 * @param milliseconds - Milliseconds
 * @returns - UTC Date object derived from the given arguments
 */
const getDateUTC = (
    year: number,
    month: number,
    day: number,
    hours = 0,
    minutes = 0,
    seconds = 0,
    milliseconds = 0
) => {
    ow(year, ow.number);
    ow(month, ow.number);
    ow(day, ow.number);
    ow(hours, ow.any(ow.null, ow.optional.number));
    ow(minutes, ow.any(ow.null, ow.optional.number));
    ow(seconds, ow.any(ow.null, ow.optional.number));
    ow(milliseconds, ow.any(ow.null, ow.optional.number));

    return new Date(Date.UTC(year, month, day, hours, minutes, seconds, milliseconds));
};

/**
 * getDateUTCFromString - Get a UTC based Date object from a properly formatted string
 * @param dateString - formatted string representing a date
 * @returns - UTC Date Object
 */
const getDateUTCFromString = (dateString: string) => {
    ow(dateString, ow.string);

    const date = parseLocalDate(dateString);
    return new Date(date.getTime());
};

/**
 * getLocalDateFromSeconds - Get a Date object in the local time zone given the number of seconds since January 1, 1970 UTC
 * @param seconds - Seconds since January 1, 1970 UTC
 * @returns - Local time zone Date object
 */
const getLocalDateFromSeconds = (seconds: number) => {
    ow(seconds, ow.number.not.negative);

    const milliseconds = seconds * 1000;
    return new Date(milliseconds);
};

/**
 * getUtcDateFromSeconds - Get a Date object in UTC given seconds since January 1, 1970 UTC
 * @param seconds - Seconds since January 1, 1970 UTC
 * @returns - UTC Date object
 */
const getUtcDateFromSeconds = (seconds: number) => {
    ow(seconds, ow.number.not.negative);

    const milliseconds = seconds * 1000;
    const date = new Date(milliseconds);

    return utcToZonedTime(date, "UTC");
};

/**
 * getSecondsFromDate - Get the amount of seconds since January 1, 1970 UTC
 * @param date - UTC Date object
 * @returns - Seconds since January 1, 1970 UTC
 */
const getSecondsFromDate = (date: Date) => {
    ow(date, ow.date);

    return Math.round(date.getTime() / 1000);
};

/**
 * parseLocalDate - Get a Date object in the local time zone given a properly formatted string and expectation format.
 * @param date - Date string
 * @param formatString - Format of Date string
 * @returns - Local time zone Date object
 * @throws Throws an exception if the date string is not parsable
 */
const parseLocalDate = (date: string, formatString = "yyyy-MM-dd") => {
    const parsedDate = new Date();
    return parse(date, formatString, parsedDate);
};

/**
 * parseUtcDate - Get a Date object in UTC given a properly formatted string.
 * @param date - Date string
 * @returns - UTC Date object
 * @throws Throws an exception if the date string is not parsable
 */
const parseUtcDate = (date: string | Date) => {
    ow(date, ow.any(ow.string, ow.date));

    return zonedTimeToUtc(date, "UTC");
};

/**
 * Get the formatted version of a date
 * @param date Date to be formatted
 * @param formatString format string (See https://date-fns.org/v2.21.1/docs/format for full list of valid units)
 * @param locale Object to use language locale for the returned date
 * @returns A long localized representation of a date
 */
const formatDate = (
    date: Date | number,
    formatString = "yyyy-MM-dd",
    locale = { locale: enUS }
) => {
    ow(date, ow.any(ow.date, ow.number));
    ow(formatString, ow.string);
    ow(locale, ow.object);
    return format(date, formatString, locale);
};

/**
 * Get the formatted time of a date
 * @param date Date to be formatted
 * @param formatString format string (See https://date-fns.org/v2.21.1/docs/format for full list of valid units)
 * @param locale Object to use language locale for the returned date
 * @returns A long localized representation of a date
 */
const formatTime = (date: Date | number, formatString = "HH:mm", locale = { locale: enUS }) => {
    ow(date, ow.any(ow.date, ow.number));
    ow(formatString, ow.string);
    ow(locale, ow.object);
    return format(date, formatString, locale);
};

/**
 * Get precision-safe sum of values
 * @param args - Numbers to sum
 * @returns The sum of the passed values
 */
const precisionSafeSum = (...args: number[]) =>
    args.reduce((accumulator: number, next: number) => currency(accumulator).add(next).value, 0);

/**
 * Get the integer value of the USD amount or display the cents in case it have some
 * @param value - the value to format
 * @returns - The amount in dollars
 */
const formatUSDCurrency = (value: number) => {
    const fractionaryDigits = (value * 100) % 100 === 0 ? 0 : 2;
    return formatCurrency(value, currencyKinds.usd, {
        minimumFractionDigits: fractionaryDigits,
        maximumFractionDigits: fractionaryDigits,
    });
};

/**
 * getUSDFromCents - Gets the amount in dollars from cents
 * @param cents - The amount in cents
 * @returns - The amount in dollars
 */
const getUSDFromCents = (cents: number) => currency(cents, { fromCents: true, precision: 2 }).value;

/**
 * isPositiveNumber - Determine if a value is a positive number
 * @param value - number to check
 * @returns - True if value is both a number, a float, and positive, otherwise false
 */
const isPositiveNumber = (value: unknown) => {
    return is.number(value) && value > 0;
};

/**
 * isNumberInRange - Determine if a value is a number and in the given range
 * @param value - number to check
 * @param minValue - min value in range
 * @param maxValue - max value in range
 * @returns - True if value is both a number and within the given range
 */
const isNumberInRange = (value: number, minValue: number, maxValue: number) =>
    is.number(value) &&
    is.number(minValue) &&
    is.number(maxValue) &&
    value <= maxValue &&
    value >= minValue;

/**
 * Converts a class instance to an object
 * @param classInstance - class instance to be converted
 * @returns Corresponding object from the class
 */
function classToObject(classInstance: object) {
    return JSON.parse(JSON.stringify(classInstance));
}

/**
 * Fetches file from URI into a blob.
 * @param uri - The file URI
 * @returns - The generated blob.
 */
async function downloadBlobFromUri(uri: string) {
    ow(uri, ow.string.nonEmpty);

    const response = await fetch(uri);
    return await response.blob();
}

/**
 * Downloads blob.
 * @param blob - Blob to download.
 * @param fileName - The file name.
 */
function downloadBlob(blob: Blob, fileName: string) {
    ow(blob, ow.object);
    ow(fileName, ow.string.nonEmpty);
    FileSaver.saveAs(blob, fileName);
}

/**
 * Clones an object with JSON parse/stringify strategy
 * To be used with primitive Javascript types and objects containing those types
 * @param objectToClone - object to be cloned
 * @returns cloned object
 */
function deepClonePrimitiveObject(objectToClone: object) {
    return JSON.parse(JSON.stringify(objectToClone));
}

/**
 * Returns the value or null
 * @param candidate - The candidate
 * @returns Returns value or null
 */
function getValueOrNull<T>(candidate: T | null | undefined): T | null {
    if (is.nullOrUndefined(candidate)) {
        return null;
    }

    return candidate;
}

/**
 * Returns the boolean or null
 * @param candidate - The candidate
 * @returns Returns value or null
 */
function getBooleanOrNull(candidate: unknown) {
    return is.boolean(candidate) ? candidate : null;
}

/**
 * Returns the array or an empty array
 * @param candidate - The candidate
 * @returns Returns value or empty array
 */
function getArrayOrEmpty<T>(candidate: T): T | [] {
    return is.array(candidate) ? candidate : [];
}

/**
 * Evaluates if the file name exists and returns a new unique name recursively
 * @param fileName - The name of the file to look if exists
 * @param files - The files agains to check for repeated names
 * @param index - Helper to create a new unique name
 * @returns Returns the unique file name
 */
function getUniqueName(fileName: string, files: { name: string }[], index = 0): string {
    let name = fileName;
    let ext = "";

    if (index) {
        const array = name.split(".");
        ext = array.pop() as string;
        name = `${array.join(".")} (${index}).${ext}`;
    }

    const nameExists = files.filter((f) => f.name === name).length > 0;
    return nameExists ? getUniqueName(fileName, files, index + 1) : name;
}

type NotIndexPredicate = (element: unknown, index: number) => boolean;
/**
 * Function that returns a predicate for determining whether the current index is not the removeIndex
 * @param removeIndex - index to filter out
 * @returns - predicate
 */
function notIndex(removeIndex: number): NotIndexPredicate {
    return (_element, index) => removeIndex !== index;
}

/**
 * Checks if a string contains only whitespaces
 * @param text - The text to be checked
 * @returns Boolean indicating if the text contains only whitespaces or not.
 */
const isWhitespace = (text: string) => !is.emptyString(text) && is.emptyStringOrWhitespace(text);

/**
 * Checks if the value is a React Fragment or not.
 * @param value - The value to be checked
 * @returns Boolean indicating if the value is a React fragment or not.
 */
// TODO: MVP-8390: Add TypeScript types to this method
const isReactFragment = (value: unknown) => {
    if (!React.isValidElement(value)) {
        return false;
    }
    if (value.type) {
        return value.type === React.Fragment;
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return value === React.Fragment;
};

/**
 * Formats string using string, number, and react components as parameters.
 * Uses react-string-format behind the scenes, but replaces any "whitespace fragments" (<>{" "}</>)
 * with single whitespaces (" ") from the output.
 * @param text - The string to be formatted
 * @param params - The values (string, number, and react components) to be used as replacements in the text
 * @returns The formatted string or React element.
 */
// TODO: MVP-8390: Add TypeScript types to this method
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const formatText = (text: string, ...params) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const isWhitespaceFragment = (value) => {
        if (isReactFragment(value?.props?.children)) {
            return isWhitespace(value.props.children.props.children);
        } else {
            return false;
        }
    };

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const stripNonBreakingSpaces = (value) =>
        React.Children.map(value.props.children, (child) =>
            isWhitespaceFragment(child) ? " " : child
        );

    const result = reactStringFormat(text, ...params);
    return React.isValidElement(result) ? <>{stripNonBreakingSpaces(result)}</> : result;
};

/**
 * Merges two objects
 * @param object - base merge object
 * @param mergeObject - object to be merged in
 * @returns merged object
 */
function mergeObjects(object: object, mergeObject: object) {
    const result = { ...object, ...mergeObject };
    Object.keys(mergeObject).forEach((key) => {
        // TODO: MVP-8391 Add TypeScript types to this method
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        result[key] = deepMergeObjects(object[key], mergeObject[key]);
    });
    return result;
}

/**
 * Deep merges two objects
 * @param object - base merge object
 * @param mergeObject - object to be merged in
 * @returns merged object
 */
function deepMergeObjects(object: object, mergeObject: object) {
    if (is.nullOrUndefined(object) && !is.nullOrUndefined(mergeObject)) {
        return mergeObject;
    }

    if (!is.nullOrUndefined(object) && is.nullOrUndefined(mergeObject)) {
        return object;
    }

    if (
        is.object(object) &&
        is.nonEmptyObject(object) &&
        is.object(mergeObject) &&
        is.nonEmptyObject(mergeObject)
    ) {
        if (!is.array(object) && !is.array(mergeObject)) {
            return mergeObjects(object, mergeObject);
        }
        // When the property to merge is an array, we return the mergeObject's property value.
        // We don't try to merge the arrays as it could lead to inconsistencies.
        return mergeObject;
    }

    return mergeObject;
}

/**
 * Determines if two addresses are the same
 * @param address1 - The first address
 * @param address2 - The second address
 * @returns Returns true if both addresses are the same or if they're both null
 */
const areAddressesSame = (address1: Partial<Address> | null, address2: Partial<Address> | null) =>
    address1 === null && address2 === null
        ? true
        : address1?.street === address2?.street &&
          address1?.street2 === address2?.street2 &&
          address1?.city === address2?.city &&
          address1?.state === address2?.state &&
          address1?.zipCode === address2?.zipCode;

/**
 * Determines if a string is blank
 * @param [str] - The string
 * @returns True if the string is null, undefined, empty, or whitespaces;
 * false otherwise
 */
const isStringBlank = (str?: string | null) => {
    ow(str, ow.any(ow.nullOrUndefined, ow.string));
    return is.nullOrUndefined(str) || is.emptyStringOrWhitespace(str);
};

/**
 * Determines if the given address is blank
 * @param [address] - The address
 * @param [address.street] - The street
 * @param [address.street2] - The street2
 * @param [address.city] - The city
 * @param [address.state] - The state
 * @param [address.zipCode] - The zip code
 * @returns Returns true if the address is null,
 * undefined, or empty, and all its fields are either null, undefined, empty,
 * or whitespaces.
 */
const isAddressBlank = (address?: Partial<Address> | null) => {
    if (is.nullOrUndefined(address) || is.emptyObject(address)) {
        return true;
    }

    ow(
        address,
        ow.object.partialShape({
            street: ow.any(ow.nullOrUndefined, ow.string),
            street2: ow.any(ow.nullOrUndefined, ow.string),
            city: ow.any(ow.nullOrUndefined, ow.string),
            state: ow.any(ow.nullOrUndefined, ow.string),
            zipCode: ow.any(ow.nullOrUndefined, ow.string),
        })
    );

    return (
        isStringBlank(address.street) &&
        isStringBlank(address.street2) &&
        isStringBlank(address.city) &&
        isStringBlank(address.state) &&
        isStringBlank(address.zipCode)
    );
};

/**
 * Taken from: https://medium.com/javascript-scene/reduce-composing-software-fe22f0c39a1d,
 * modified for readability.
 * Allows applying a set of transformations, in sequence, to a set of data.
 * @param functions - Transformations to apply.
 * @returns - Result after applying all transformations.
 */
const pipe =
    <T,>(...functions: ((d: T, ...args: T[]) => T)[]) =>
    (data: T, ...additionalData: T[]) =>
        functions.reduce((updatedData, func) => func(updatedData, ...additionalData), data);

/**
 * Transforms a boolean string ("true"/"false") to a boolean value.
 * This function is useful since some UI components return the user's
 * selected boolean value as a string, and "false" might be treated as
 * a truthy value.
 * @param candidate - Boolean string.
 * @returns - Boolean value.
 */
const stringToBoolean = (candidate: string) => {
    if (!is.nonEmptyStringAndNotWhitespace(candidate)) {
        return null;
    }
    if (candidate.toLowerCase() === "true") {
        return true;
    }
    if (candidate.toLowerCase() === "false") {
        return false;
    }
    return null;
};

/**
 * Checks whether the first and last name of a name object isn't blank.
 * @param [name] - name object.
 * @param [name.firstName] - The first name
 * @param [name.lastName] - The last name
 * @returns - True if both the first and last names are not blank, false if either are blank
 */
const isNameComplete = (name?: { firstName?: string | null; lastName?: string | null } | null) => {
    ow(
        name,
        ow.any(
            ow.nullOrUndefined,
            ow.object.partialShape({
                firstName: ow.any(ow.nullOrUndefined, ow.string),
                lastName: ow.any(ow.nullOrUndefined, ow.string),
            })
        )
    );

    return !isStringBlank(name?.firstName) && !isStringBlank(name?.lastName);
};

/**
 * Checks whether the street, city and state of an address object isn't blank and have a valid zip code.
 * @param [address] - address object.
 * @param [address.street] - The street
 * @param [address.city] - The city
 * @param [address.state] - The state
 * @param [address.zipCode] - The zip code
 * @returns - True if the street, city and state are not blank and zip code is valid, false if
 * either are blank or zip code invalid
 */
const isAddressComplete = (address?: Partial<Address> | null) => {
    ow(
        address,
        ow.any(
            ow.nullOrUndefined,
            ow.object.partialShape({
                street: ow.any(ow.nullOrUndefined, ow.string),
                street2: ow.any(ow.nullOrUndefined, ow.string),
                city: ow.any(ow.nullOrUndefined, ow.string),
                state: ow.any(ow.nullOrUndefined, ow.string),
                zipCode: ow.any(ow.nullOrUndefined, ow.string),
            })
        )
    );

    return (
        !isStringBlank(address?.street) &&
        !isStringBlank(address?.city) &&
        !isStringBlank(address?.state) &&
        validateZipCode(address?.zipCode)
    );
};

const NumberComparisonEnums = {
    LessThan: "LessThan",
    EqualTo: "EqualTo",
    GreaterThan: "GreaterThan",
} as const;

type NumberComparisonEnums =
    | typeof NumberComparisonEnums[keyof typeof NumberComparisonEnums]
    | null;

/**
 * Compares two numbers and returns a comparison enum
 * @param a - The first number
 * @param b - The second number
 * @returns - The comparison enum
 */
const numberComparisonMapper = (a: number, b: number): NumberComparisonEnums => {
    if (!is.number(a) || !is.number(b)) {
        return null;
    }
    if (a === b) {
        return NumberComparisonEnums.EqualTo;
    }
    if (a < b) {
        return NumberComparisonEnums.LessThan;
    } else {
        return NumberComparisonEnums.GreaterThan;
    }
};

/**
 * Gets the wildcard path for a given path
 * @param path - The path
 * @returns - The wildcard path
 */
const getWildPath = (path: string) => {
    ow(path, ow.string.nonEmpty);

    const lastChar = path.charAt(path.length - 1);

    if (lastChar === "*") {
        return path;
    }

    if (lastChar === "/") {
        return `${path}*`;
    }

    return `${path}/*`;
};

/**
 * Strips a period and any spaces at the end of the provided string
 * @param string - The string to modify
 * @returns - The string with no end period or spaces
 */
const stripEndPeriodAndEndSpaces = (string: string) => {
    if (is.nullOrUndefined(string)) {
        return null;
    }

    return string.replace(/(\.| )+$/, "");
};

const millisecondsPerSecond = 1000;

/**
 * Converts seconds to milliseconds
 * @param seconds - The seconds to convert
 * @returns - The equivalent number of milliseconds
 */
const secondsToMilliseconds = (seconds: number) => seconds * millisecondsPerSecond;

/**
 * Converts milliseconds to seconds
 * @param milliseconds - The milliseconds to convert
 * @returns - The equivalent number of seconds
 */
const millisecondsToSeconds = (milliseconds: number) => milliseconds / millisecondsPerSecond;

/**
 * Integrates a specified property with a value into an array of objects based on a matching key.
 * @param listOfItems - The array of objects to be modified.
 * @param itemKey - The key to match in the objects within `listOfItems`.
 * @param property - The property of the object to set the value for.
 * @param value - The value to set for the specified property.
 */
const integrateProperty = (
    listOfItems: { [key: string]: unknown }[],
    itemKey: string,
    property: string,
    value: unknown
) => {
    const itemIndex = listOfItems.findIndex((item) => item.key === itemKey);
    if (itemIndex !== -1) {
        listOfItems[itemIndex][property] = value;
    }
};

/**
 * Determines if a given date is more than one year older than the current date.
 * @param [dateString] - The date string to check, in a format parseable by Date.
 * @returns True if the date is older than one year, otherwise false.
 */
const isDateOlderThanOrEqualToOneYear = (dateString?: string | null) => {
    if (!validateDateString(dateString)) {
        return false;
    }

    return differenceInYears(new Date(), new Date(dateString as string)) >= 1;
};

/**
 * Removes numeric digits from a given string value.
 * @param value - The string value from which to remove numeric digits.
 * @returns A new string with numeric digits removed.
 */
const removeNumericDigits = (value: string) => value.replace(/\d/g, "");

/**
 * Removes all new line characters from a given string. Throws an error if the input is not a string.
 * @param text - The string from which to remove new line characters.
 * @returns - The modified string without new line characters.
 * @throws {ArgumentError} - Throws an error if the input is not a string.
 */
function removeNewLines(text: string) {
    ow(text, ow.string);

    const NEW_LINE_REGEX = /[\r\n]/gm;
    return text.replace(NEW_LINE_REGEX, " ");
}

const getParticipantName = (participant: Participant) => {
    if (!is.boolean(participant?.isEntity)) {
        return null;
    }

    return participant.isEntity
        ? getValueOrNull(participant.business?.name)
        : formatName(participant.person);
};

const isValidUrl = (url: string | URL) => {
    try {
        new URL(url);
        return true;
    } catch (e) {
        return false;
    }
};

interface attemptWithRetryOptions {
    maxAttemptCount: number;
    msBetweenAttempts: number;
}

const attemptWithRetryAsync = async <T,>(
    asyncFunc: () => Promise<T>,
    successCriteria: (result: T) => boolean,
    options: attemptWithRetryOptions = { maxAttemptCount: 5, msBetweenAttempts: 1000 }
): Promise<T | null> => {
    for (let x = 0; x < options.maxAttemptCount; x++) {
        const result = await asyncFunc();

        if (successCriteria(result)) {
            return result;
        } else {
            await new Promise((resolve) => setTimeout(resolve, options.msBetweenAttempts));
        }
    }

    return null;
};

export {
    removeNewLines,
    isDateOlderThanOrEqualToOneYear,
    integrateProperty,
    getSubsectionKeyFromPath,
    getLastUrlPathSegment,
    getNowDateUTC,
    getLocalDateFromSeconds,
    getUtcDateFromSeconds,
    getSecondsFromDate,
    getDateUTC,
    getDateUTCFromString,
    getUSDFromCents,
    downloadBlobFromUri,
    classToObject,
    precisionSafeSum,
    parseLocalDate,
    parseUtcDate,
    formatUSDCurrency,
    formatDate,
    formatTime,
    isPositiveNumber,
    isNumberInRange,
    isDateBefore,
    isDateEqual,
    isDateLaterThanOrEqualToToday,
    isDateAfter,
    downloadBlob,
    deepClonePrimitiveObject,
    getValueOrNull,
    getBooleanOrNull,
    getArrayOrEmpty,
    getUniqueName,
    notIndex,
    formatText,
    deepMergeObjects,
    areAddressesSame,
    isAddressBlank,
    isStringBlank,
    pipe,
    stringToBoolean,
    isNameComplete,
    isAddressComplete,
    NumberComparisonEnums,
    numberComparisonMapper,
    getWildPath,
    stripEndPeriodAndEndSpaces,
    secondsToMilliseconds,
    millisecondsToSeconds,
    removeNumericDigits,
    getParticipantName,
    isValidUrl,
    attemptWithRetryAsync,
};

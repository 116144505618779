import UserTracker from "@q4justice/q4j-user-tracking";
import is from "@sindresorhus/is";
import log from "loglevel";
import { ClaimKind } from "../../models/claimKind";
import { isStringWithContent } from "../../utils/validationUtils";
import {
    authEvents,
    emailEvents,
    downloadEvents,
    purchaseEvents,
    defendantDefenseKindEvents,
    claimKindEvents,
    errorEvents,
} from "./constants";

/**
 * @typedef AccountDetails
 * @property {string | number} accountId - The account id
 * @property {string} email - The email
 * @property {string} firstName - The first name
 * @property {string} lastName - The last name
 * @property {string} [preferredLanguage] - Optional preferred language
 */

// Client to handle user tracking
class UserTrackingClient {
    constructor() {
        const isGtmEnabled =
            isStringWithContent(process.env.REACT_APP_GTM_CONTAINER_ID) &&
            isStringWithContent(process.env.REACT_APP_GTM_AUTH_TOKEN) &&
            isStringWithContent(process.env.REACT_APP_GTM_PREVIEW_ENV);

        this.trackerOptions = {
            heap: {
                is_enabled: isStringWithContent(process.env.REACT_APP_HEAP_KEY),
                token: process.env.REACT_APP_HEAP_KEY,
            },
            newrelic: {
                is_enabled: true,
                accountId: process.env.REACT_APP_NR_ACCOUNT_ID,
                trustKey: process.env.REACT_APP_NR_TRUST_KEY,
                agentId: process.env.REACT_APP_NR_AGENT_ID,
                licenseKey: process.env.REACT_APP_NR_LICENSE_KEY,
                applicationId: process.env.REACT_APP_NR_APPLICATION_ID,
                sa: process.env.REACT_APP_NR_SA,
            },
            gtm: {
                is_enabled: isGtmEnabled,
                containerId: process.env.REACT_APP_GTM_CONTAINER_ID,
                authToken: process.env.REACT_APP_GTM_AUTH_TOKEN,
                previewEnvironment: process.env.REACT_APP_GTM_PREVIEW_ENV,
            },
            msclarity: {
                is_enabled: isGtmEnabled,
            },
            cohere: {
                is_enabled: isStringWithContent(process.env.REACT_APP_COHERE_KEY),
                token: process.env.REACT_APP_COHERE_KEY,
            },
        };

        try {
            this.userTracker = new UserTracker(this.trackerOptions);
        } catch (e) {
            log.error("Unable to initialize the user tracker.", e);
        }
    }

    /**
     * Initializes the user tracker with the provided user account details
     * @param {AccountDetails} details - The user account details.
     * @returns {void} - Returns nothing.
     */
    identifyUser(details) {
        if (is.nullOrUndefined(this.userTracker)) {
            return;
        }
        this.userTracker.identify(details.accountId, {
            email: details.email,
            firstName: details.firstName,
            lastName: details.lastName,
            preferredLanguage: details.preferredLanguage,
        });
    }

    /**
     * Tracks a signup event
     * @returns {void}
     */
    trackSignup() {
        if (is.nullOrUndefined(this.userTracker)) {
            return;
        }

        this.userTracker.track(authEvents.SIGNUP);
    }

    /**
     * Tracks a login event
     * @returns {void}
     */
    trackLogin() {
        if (is.nullOrUndefined(this.userTracker)) {
            return;
        }

        this.userTracker.track(authEvents.LOGIN);
    }

    /**
     * Tracks a Google signup event
     * @returns {void}
     */
    trackGoogleSignup() {
        if (is.nullOrUndefined(this.userTracker)) {
            return;
        }

        this.userTracker.track(authEvents.GOOGLE_SIGNUP);
    }

    /**
     * Tracks a Google login event
     * @returns {void}
     */
    trackGoogleLogin() {
        if (is.nullOrUndefined(this.userTracker)) {
            return;
        }

        this.userTracker.track(authEvents.GOOGLE_LOGIN);
    }

    /**
     * Tracks a logout event
     * @returns {void}
     */
    trackLogout() {
        if (is.nullOrUndefined(this.userTracker)) {
            return;
        }

        this.userTracker.track(authEvents.LOGOUT);
    }

    /**
     * Tracks a downloaded document event
     * @param {documentKinds} document - The document that was downloaded
     * @returns {void}
     */
    trackDownload(document) {
        if (is.nullOrUndefined(this.userTracker)) {
            return;
        }

        if (!is.string(document)) {
            log.warn(`Unable to track download. document ${document} must be a string.`);
            return;
        }

        const event = downloadEvents[document];
        if (!is.string(event)) {
            log.warn(`Unable to track download. Download event for document ${document} not found`);
            return;
        }

        this.userTracker.track(event);
    }

    /**
     * Tracks an emailed document event
     * @param {documentKinds} document - The document that was emailed
     * @returns {void}
     */
    trackEmail(document) {
        if (is.nullOrUndefined(this.userTracker)) {
            return;
        }

        if (!is.string(document)) {
            log.warn(`Unable to track email. document ${document} must be a string.`);
            return;
        }

        const event = emailEvents[document];

        if (!is.string(event)) {
            log.warn(`Unable to track email. Email event for document ${document} not found`);
            return;
        }

        this.userTracker.track(event);
    }

    /**
     * Tracks a purchase event
     * @param {string} purchaseKind - purchaseKind
     * @returns {void}
     */
    trackPurchase(purchaseKind) {
        if (is.nullOrUndefined(this.userTracker)) {
            return;
        }

        const event = purchaseEvents[purchaseKind];

        if (!is.nonEmptyStringAndNotWhitespace(event)) {
            log.warn(
                `Unable to track purchase. Purchase event for purchase kind ${purchaseKind} not found`
            );
            return;
        }

        this.userTracker.track(event);
    }

    /**
     * Tracks a defendant defense kind change event
     * @param {string} defendantDefenseKind - defendantDefenseKind
     * @returns {void}
     */
    trackDefendantDefenseKind(defendantDefenseKind) {
        if (is.nullOrUndefined(this.userTracker)) {
            return;
        }

        const event = defendantDefenseKindEvents[defendantDefenseKind];

        if (!is.nonEmptyStringAndNotWhitespace(event)) {
            log.warn(
                `Unable to track defendant defense kind change. Event for defendant defense kind ${defendantDefenseKind} not found`
            );
            return;
        }

        this.userTracker.track(event);
    }

    /**
     * Tracks a claim kind event. Sends the "Unknown" event by default.
     * @param {string} claimKind - The claim kind
     * @returns {void}
     */
    trackClaimKind(claimKind) {
        if (is.nullOrUndefined(this.userTracker)) {
            return;
        }

        let event = claimKindEvents[claimKind];
        if (is.nullOrUndefined(event)) {
            event = claimKindEvents[ClaimKind.Unknown];
        }

        this.userTracker.track(event);
    }

    /**
     * Tracks an "app crash" -- a colloquial term to describe the ErrorBoundary getting triggered.
     * @param {Error} error - The error.
     */
    trackAppCrash(error) {
        if (is.nullOrUndefined(this.userTracker)) {
            return;
        }
        this.userTracker.track(errorEvents.AppCrash, {
            message: error.message,
            stack: error.stack,
        });
    }

    /**
     * Resets the user tracker
     * @returns {void}
     */
    reset() {
        if (is.nullOrUndefined(this.userTracker)) {
            return;
        }

        this.userTracker.reset();
    }
}

const userTrackingClient = new UserTrackingClient();

export { userTrackingClient as default, UserTrackingClient };

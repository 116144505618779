import { createContext } from "react";

/** @typedef {import('xstate').State} State */

const noOp = () => {};

/**
 * @typedef AccountContextEvents
 * @property {string} startUpdatingName - Event to start the update name flow.
 * @property {string} sendUpdatedName - Event to update the name.
 * @property {string} goToIdleState - Event to transition to the Account machine's idle state.
 */

/**
 * @typedef AccountContext
 * @property {State} accountMachineState - Account machine's state.
 * @property {(event: any) => void} accountMachineSend - Sends an event to the Account machine.
 * @property {boolean} isLoading - Whether or not the account machine is in a loading state.
 * @property {string} currentErrorCondition - Account machine's current error.
 * @property {boolean} isNameDialogOpen - Whether or not the update name dialog is open.
 * @property {boolean} wasNameUpdateSuccessful - Whether or not the update name process succeeded.
 * @property {AccountContextEvents} events - Events to send to the Account machine.
 */

/** @type {AccountContext} */
const initialContext = {
    accountMachineState: null,
    accountMachineSend: noOp,
    isLoading: false,
    currentErrorCondition: null,
    isNameDialogOpen: false,
    wasNameUpdateSuccessful: false,
    events: {
        startUpdatingName: "START_UPDATING_NAME",
        sendUpdatedName: "SEND_UPDATED_NAME",
        goToIdleState: "GO_TO_IDLE_STATE",
    },
};

const AccountContext = createContext(initialContext);

export default AccountContext;

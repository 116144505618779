const ErrorCodes = {
    InvalidLanguage: "InvalidLanguage",
    InvalidResponse: "InvalidResponse",
    CookieError: "CookieError",
    CreateAccountError: "CreateAccountError",
    ThirdPartyAuthenticationError: "ThirdPartyAuthenticationError",
    ThirdPartyAuthenticationEmailExistsError: "ThirdPartyAuthenticationEmailExistsError",
    GetAccountError: "GetAccountError",
    Unauthenticated: "Unauthenticated",
    AlreadyExistsError: "AlreadyExistsError",
    ClearAccountError: "ClearAccountError",
    UpdateAccountError: "UpdateAccountError",
    DeactivateAccountError: "DeactivateAccountError",
    AuthenticationError: "AuthenticationError",
    UpdateEmailError: "UpdateEmailError",
    UpdatePasswordError: "UpdatePasswordError",
    SendEmailVerificationError: "SendEmailVerificationError",
    VerifyEmailError: "VerifyEmailError",
    ForgotPasswordError: "ForgotPasswordError",
    ResetPasswordError: "ResetPasswordError",
    OfflineBrowser: "OfflineBrowser",
    EmailAlreadyVerified: "EmailAlreadyVerified",
    NotFound: "NotFound",
    PermissionDenied: "PermissionDenied",
};

export default ErrorCodes;

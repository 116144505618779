import is from "@sindresorhus/is";
import i18n, { changeLanguage } from "i18next";
import log from "loglevel";
import ow from "ow";
import userTrackingClient from "../../clients/UserTracking";
import questUIStrings from "../../constants/strings";
import {
    AuthenticationRequest,
    CreateAccountRequest,
    GoogleAuthenticationDetails,
    ThirdPartyAuthenticationRequest,
    UpdateAccountRequest,
    UpdatePasswordRequest,
    DeactivateAccountRequest,
    VerifyEmailRequest,
    ForgotPasswordRequest,
    ResetPasswordRequest,
    UpdateEmailRequest,
} from "../../grpc/account_pb";
import GrpcHelpers from "../../grpc/GrpcHelpers";
import GrpcStatusCodes from "../../grpc/GrpcStatusCodes";
import {
    mapLanguageKindEnumToLanguage,
    mapLanguageToLanguageKindEnum,
} from "../../mappers/accountMachineMapper";
import { getValueOrNull } from "../../utils/commonUtils";
import { getLanguageByLanguageCode } from "../../utils/languageUtils";
import cookieManager from "../cookieManager";
import accountsClient from "./accountsClient";
import AccountServiceError from "./AccountServiceError";
import ErrorCodes from "./ErrorCodes";

/**
 * @typedef AccountDetails
 * @property {number} accountId - The account ID
 * @property {string} authToken - The authentication token
 * @property {string} email - The account email
 * @property {string} firstName - The first name
 * @property {string} lastName - The last name
 * @property {boolean} isEmailVerified - True if the email is verified, false otherwise
 * @property {boolean} isThirdPartyAuth - True if it is a third-party authenticated account, false otherwise
 * @property {string} preferredLanguageCode - The preferred language code
 */

/**
 * @typedef AuthenticationDetails
 * @property {string} loginId - The login id
 * @property {number} accountId - The account id
 * @property {string} authToken - The authentication token
 * @property {string} email - The account email
 * @property {string} firstName - The first name
 * @property {string} lastName - The last name
 * @property {{key: string, displayText: string, languageCode: string}} preferredLanguageCode - The preferred language code
 * @property {boolean} isAdmin - True if it is an admin account, false otherwise
 * @property {boolean} isEmailVerified - True if the email is verified, false otherwise
 * @property {boolean} wasAccountCreated - True if the account was created, only used for third-party authentication
 */

/**
 * @typedef LoginResponse
 * @property {number} accountId - The account id
 * @property {string} authToken - The authentication token
 * @property {boolean} isAdmin - True if it is an admin account, false otherwise
 * @property {string} email - The account email
 * @property {string} firstName - The first name
 * @property {string} [lastName] - The last name
 * @property {string| null} preferredLanguageCode - The preferred language code
 * @property {boolean} isEmailVerified - True if the email is verified, false otherwise
 * @property {boolean} isThirdPartyAuth - True if authenticated through a third party, false otherwise
 */

/**
 * @typedef {import("../../grpc/account_pb.d.ts").UpdatePasswordResponse.AsObject} UpdatePasswordResponse
 */

/**
 * @typedef {import("../../grpc/account_pb.d.ts").UpdateEmailResponse.AsObject} GrpcUpdateEmailResponse
 * @typedef {GrpcUpdateEmailResponse & {email: string}} UpdateEmailResponse
 */

const MINIMUM_PASSWORD_LENGTH = 8;

class AccountService {
    constructor(accountsClient, cookieManager, userTrackingClient) {
        if (is.nullOrUndefined(accountsClient)) {
            throw new Error("accountsClient is null or undefined.");
        }

        if (is.nullOrUndefined(cookieManager)) {
            throw new Error("cookieManager is null or undefined.");
        }

        if (is.nullOrUndefined(userTrackingClient)) {
            throw new Error("userTrackingClient is null or undefined.");
        }

        this.accountsClient = accountsClient;
        this.cookieManager = cookieManager;
        this.userTrackingClient = userTrackingClient;
    }

    /**
     * Gets the current account
     * @returns {Promise<object>} The account details
     * @throws {AccountServiceError} Thrown if there was an error getting the account
     */
    async getAccount() {
        const request = GrpcHelpers.buildEmpty();

        let response;
        try {
            response = await this.accountsClient.getAccount(request, this._getMetadata(true));
        } catch (error) {
            if (error.code === GrpcStatusCodes.statusCodes.unauthenticated) {
                const message = "Unauthenticated";
                log.debug(message);
                throw new AccountServiceError(message, ErrorCodes.Unauthenticated);
            } else {
                const message = `Error getting account: ${error}`;
                log.debug(message);
                throw new AccountServiceError(message, ErrorCodes.GetAccountError);
            }
        }

        if (is.nullOrUndefined(response)) {
            const message = "Response is null or undefined";
            log.debug(message);
            throw new AccountServiceError(message, ErrorCodes.InvalidResponse);
        }

        const data = response.toObject();
        if (!is.nonEmptyObject(data)) {
            const message = "Response data is invalid";
            log.debug(message);
            throw new AccountServiceError(message, ErrorCodes.InvalidResponse);
        }

        const authenticationDetails = {
            id: data?.id,
            email: data?.email?.value,
            firstName: data?.firstName?.value,
            middleName: data?.middleName?.value,
            lastName: data?.lastName?.value,
            birthDate: data?.birthDate,
            phoneNumber: data?.phoneNumber?.value,
            isAdmin: data?.isAdmin,
            preferredLanguageCode: mapLanguageKindEnumToLanguage(data?.preferredLanguage)
                ?.languageCode,
            linkedThirdPartyAccounts: data?.linkedThirdPartyAccounts,
            isEmailVerified: data?.isEmailVerified?.value,
            peopleClerkUserId: data?.peopleClerkUserId?.value,
            isActive: data?.isActive,
        };

        return authenticationDetails;
    }

    /**
     * Checks if the user is logged in
     * @returns {Promise<boolean>} True if the user is logged on, false otherwise
     * @throws {AccountServiceError} Thrown if there was an error while verifying if the user is logged in.
     */
    async isLoggedIn() {
        // Checks that an auth cookie exists
        const storedAccountDetails = this.cookieManager.getAccountDetails();

        // TODO: MVP-2718: Set user fullName properties once AccountService supports a full user data response
        if (
            !is.nonEmptyObject(storedAccountDetails) ||
            !is.nonEmptyStringAndNotWhitespace(storedAccountDetails.email) ||
            !is.nonEmptyStringAndNotWhitespace(storedAccountDetails.authToken) ||
            !is.number(storedAccountDetails.accountId)
        ) {
            return false;
        }

        const accountDetails = await this.getAccount();

        const isLoggedIn =
            storedAccountDetails.accountId === accountDetails.id &&
            // This will ignore casing but not accents (e.g. "a" is different from "ä").
            storedAccountDetails.email.localeCompare(accountDetails.email, undefined, {
                sensitivity: "accent",
            }) === 0;

        return isLoggedIn;
    }

    /**
     * Log in
     * @param {string} email - Email
     * @param {string} password - Password
     * @returns {Promise<LoginResponse>} Promise that resolves to the login response
     * @throws {AccountServiceError} Thrown if there is an error during login
     */
    async login(email, password) {
        ow(email, ow.string.nonEmpty);
        ow(password, ow.string.nonEmpty);

        const request = new AuthenticationRequest();
        request.setEmail(GrpcHelpers.buildStringValue(email));
        request.setPassword(GrpcHelpers.buildStringValue(password));

        let grpcResponse;
        try {
            grpcResponse = await this.accountsClient.authenticate(request, this._getMetadata());
        } catch (error) {
            log.debug("Error calling login:", error);
            throw new AccountServiceError(
                "Error logging in: error calling accountService.authenticate",
                ErrorCodes.AuthenticationError
            );
        }

        if (is.nullOrUndefined(grpcResponse)) {
            throw new AccountServiceError(
                "Authenticate response is null or undefined",
                ErrorCodes.InvalidResponse
            );
        }

        const response = grpcResponse.toObject();

        if (
            !(
                is.nonEmptyObject(response) &&
                is.number(response.accountId) &&
                is.nonEmptyString(response.authToken) &&
                is.boolean(response.isAdmin)
            )
        ) {
            const errorMessage = "Error calling login: response data is invalid";
            log.debug(errorMessage);
            throw new AccountServiceError(errorMessage, ErrorCodes.InvalidResponse);
        }

        const data = {
            accountId: response.accountId,
            authToken: response.authToken,
            isAdmin: response.isAdmin,
            email: response.email?.value,
            firstName: response.firstName?.value,
            lastName: response.lastName?.value,
            preferredLanguageCode: getValueOrNull(
                mapLanguageKindEnumToLanguage(response.preferredLanguage)?.languageCode
            ),
            isEmailVerified: response.isEmailVerified?.value ?? false,
        };

        try {
            this.cookieManager.updateAccountDetails({
                accountId: data.accountId,
                authToken: data.authToken,
                email: data.email,
                firstName: data.firstName,
                lastName: data.lastName,
                preferredLanguageCode: data.preferredLanguageCode,
                isEmailVerified: data.isEmailVerified,
                isThirdPartyAuth: false,
            });
        } catch (error) {
            const errorMessage = `Error updating cookie on login: ${error}`;
            log.debug(errorMessage);
            throw new AccountServiceError(errorMessage, ErrorCodes.CookieError);
        }

        this._trackUserDetails(data);

        // Track login event
        try {
            this.userTrackingClient.trackLogin();
        } catch (error) {
            // Don't throw an error since the app should still be functional
            log.debug(`Error tracking login: ${error}`);
        }

        try {
            await changeLanguage(response.preferredLanguageCode);
        } catch (e) {
            log.error(e);
        }

        return data;
    }

    /**
     * Signs up the user given the supplied data. If sign up is successful, then the cookies are updated and
     * tracking is handled.
     * @param {string} firstName - The first name
     * @param {string} lastName - The last name
     * @param {string} email - The account email
     * @param {string} password - The account password
     * @param {string | null} [preferredLanguageCode] - Optional preferred language in ISO code. Defaults to the i18n.resolvedLanguage.
     * @param {string | null} [referrer] - Optional referrer. If specified, it must be a value from {@link referrerSources}.
     * @returns {Promise<AuthenticationDetails>} The authentication details
     * @throws {ArgumentError} Thrown for an invalid parameter
     * @throws {AccountServiceError} Thrown if there was an error while signing up
     */
    async signup(firstName, lastName, email, password, preferredLanguageCode, referrer) {
        ow(firstName, ow.string.nonEmpty);
        ow(lastName, ow.string.nonEmpty);
        ow(email, ow.string.nonEmpty);
        ow(password, ow.string.minLength(MINIMUM_PASSWORD_LENGTH));

        const languageCode = is.nonEmptyString(preferredLanguageCode)
            ? preferredLanguageCode
            : i18n.resolvedLanguage;
        const language = getLanguageByLanguageCode(languageCode);
        if (is.nullOrUndefined(language)) {
            const message = `Language code ${languageCode} is invalid`;
            log.debug(message);
            throw new AccountServiceError(message, ErrorCodes.InvalidLanguage);
        }

        const request = new CreateAccountRequest();
        request.setFirstName(GrpcHelpers.buildStringValue(firstName));
        request.setLastName(GrpcHelpers.buildStringValue(lastName));
        request.setEmail(GrpcHelpers.buildStringValue(email));
        request.setPassword(GrpcHelpers.buildStringValue(password));
        request.setPreferredLanguage(mapLanguageToLanguageKindEnum(language));

        let response;
        try {
            response = await this.accountsClient.createAccount(request, this._getMetadata());
        } catch (error) {
            if (error.code === GrpcStatusCodes.statusCodes.alreadyExists) {
                const message = "Error signing up: existing email";
                log.debug(message);
                throw new AccountServiceError(message, ErrorCodes.AlreadyExistsError);
            } else {
                const message = `Error signing up: ${error}`;
                log.debug(message);
                throw new AccountServiceError(message, ErrorCodes.CreateAccountError);
            }
        }

        if (is.nullOrUndefined(response)) {
            const message = "Response is null or undefined";
            log.debug(message);
            throw new AccountServiceError(message, ErrorCodes.InvalidResponse);
        }

        const data = response.toObject();
        if (!is.nonEmptyObject(data)) {
            const message = "Response data is invalid";
            log.debug(message);
            throw new AccountServiceError(message, ErrorCodes.InvalidResponse);
        }

        const authenticationDetails = {
            loginId: data.loginId.value,
            accountId: data.accountId,
            authToken: data.authToken,
            email: data.email.value,
            firstName: data.firstName.value,
            middleName: null,
            lastName: data.lastName.value,
            birthDate: null,
            phoneNumber: null,
            preferredLanguageCode: getValueOrNull(
                mapLanguageKindEnumToLanguage(data.preferredLanguage)?.languageCode
            ),
            isThirdPartyAuth: false,
            isAdmin: data.isAdmin,
            isEmailVerified: data.isEmailVerified.value,
        };

        // Update auth cookie
        try {
            this.cookieManager.updateAccountDetails({
                accountId: authenticationDetails.accountId,
                authToken: authenticationDetails.authToken,
                email: authenticationDetails.email,
                firstName: authenticationDetails.firstName,
                middleName: authenticationDetails.middleName,
                lastName: authenticationDetails.lastName,
                birthDate: authenticationDetails.birthDate,
                phoneNumber: authenticationDetails.phoneNumber,
                preferredLanguageCode: authenticationDetails.preferredLanguageCode,
                isThirdPartyAuth: authenticationDetails.isThirdPartyAuth,
                isEmailVerified: authenticationDetails.isEmailVerified,
            });
        } catch (error) {
            const message = `Error updating cookie on sign up: ${error}`;
            log.debug(message);
            throw new AccountServiceError(message, ErrorCodes.CookieError);
        }

        // Track the referrer
        if (is.nonEmptyString(referrer)) {
            this._trackReferral(referrer);
        }

        this._trackUserDetails(authenticationDetails);

        // Track signup event
        try {
            this.userTrackingClient.trackSignup();
        } catch (error) {
            // Don't throw an error since the app should still be functional
            log.debug(`Error tracking signup: ${error}`);
        }

        return authenticationDetails;
    }

    /**
     * Authenticates the user through a third party. If authentication is successful, then the cookies are updated and
     * tracking is handled.
     * @param {string} idToken - The id token
     * @param {string | null} [preferredLanguageCode] - Optional preferred language in ISO code. Defaults to the i18n.resolvedLanguage.
     * @param {string | null} [referrer] - Optional referrer. If specified, it must be a value from {@link referrerSources}.
     * @returns {Promise<AuthenticationDetails>} The authentication details
     * @throws {ArgumentError} Thrown for an invalid parameter
     * @throws {AccountServiceError} Thrown if there was an error while authenticating with a third-party
     */
    async thirdPartyAuthenticate(idToken, preferredLanguageCode, referrer) {
        ow(idToken, ow.string.nonEmpty);

        const languageCode = is.nonEmptyString(preferredLanguageCode)
            ? preferredLanguageCode
            : i18n.resolvedLanguage;
        const language = getLanguageByLanguageCode(languageCode);
        if (is.nullOrUndefined(language)) {
            const message = `Language code ${languageCode} is invalid`;
            log.debug(message);
            throw new AccountServiceError(message, ErrorCodes.InvalidLanguage);
        }

        const googleAuthDetails = new GoogleAuthenticationDetails();
        googleAuthDetails.setIdToken(GrpcHelpers.buildStringValue(idToken));

        const request = new ThirdPartyAuthenticationRequest();
        request.setGoogleAuthenticationDetails(googleAuthDetails);
        request.setPreferredLanguage(mapLanguageToLanguageKindEnum(language));

        let response;
        try {
            response = await this.accountsClient.thirdPartyAuthenticate(
                request,
                this._getMetadata()
            );
        } catch (error) {
            if (error.code === GrpcStatusCodes.statusCodes.alreadyExists) {
                const message = "Error logging in with third party authentication: existing email";
                log.debug(message);
                throw new AccountServiceError(
                    message,
                    ErrorCodes.ThirdPartyAuthenticationEmailExistsError
                );
            } else {
                const message = `Error authenticating with a third-party: ${error}`;
                log.debug(message);
                throw new AccountServiceError(message, ErrorCodes.ThirdPartyAuthenticationError);
            }
        }

        if (is.nullOrUndefined(response)) {
            const message = "Response is null or undefined";
            log.debug(message);
            throw new AccountServiceError(message, ErrorCodes.InvalidResponse);
        }

        const data = response.toObject();
        if (!is.nonEmptyObject(data)) {
            const message = "Response data is invalid";
            log.debug(message);
            throw new AccountServiceError(message, ErrorCodes.InvalidResponse);
        }

        const authenticationDetails = {
            accountId: data.accountId,
            authToken: data.authToken,
            email: data.email.value,
            firstName: data.firstName?.value,
            middleName: null,
            lastName: data.lastName?.value,
            birthDate: null,
            phoneNumber: null,
            preferredLanguageCode: getValueOrNull(
                mapLanguageKindEnumToLanguage(data.preferredLanguage)?.languageCode
            ),
            linkedThirdPartyAccountsList: data.linkedThirdPartyAccountsList,
            isThirdPartyAuth: true,
            isEmailVerified: data.isEmailVerified.value,
            wasAccountCreated: data.wasAccountCreated,
        };

        // Update auth cookie
        try {
            this.cookieManager.updateAccountDetails({
                accountId: authenticationDetails.accountId,
                authToken: authenticationDetails.authToken,
                email: authenticationDetails.email,
                firstName: authenticationDetails.firstName,
                middleName: authenticationDetails.middleName,
                lastName: authenticationDetails.lastName,
                birthDate: authenticationDetails.birthDate,
                phoneNumber: authenticationDetails.phoneNumber,
                preferredLanguageCode: authenticationDetails.preferredLanguageCode,
                isThirdPartyAuth: authenticationDetails.isThirdPartyAuth,
                isEmailVerified: authenticationDetails.isEmailVerified,
            });
        } catch (error) {
            const message = `Error updating cookie on authentication with a third-party: ${error}`;
            log.debug(message);
            throw new AccountServiceError(message, ErrorCodes.CookieError);
        }

        // Track the referrer for account creation
        if (authenticationDetails.wasAccountCreated && is.nonEmptyString(referrer)) {
            this._trackReferral(referrer);
        }

        this._trackUserDetails(authenticationDetails);

        // Track third-party authentication event
        try {
            if (authenticationDetails.wasAccountCreated) {
                this.userTrackingClient.trackGoogleSignup();
            } else {
                this.userTrackingClient.trackGoogleLogin();
            }
        } catch (error) {
            // Don't throw an error since the app should still be functional
            log.debug(`Error tracking third-party authentication: ${error}`);
        }

        return authenticationDetails;
    }

    /**
     * Logs out
     * @returns {void} - void
     */
    logout() {
        this._clearAccount();

        try {
            this.userTrackingClient.trackLogout();
            this.userTrackingClient.reset();
        } catch (error) {
            log.debug(`Error handling user tracking: ${error}`);
        }

        // Set language back to browser's preferred language
        try {
            changeLanguage();
        } catch (e) {
            log.error(`Error changing language: ${e}`);
        }
    }

    /**
     * Updates the account using the provided partial account object.
     * The full accountDetails object from cookieManager is then merged with the partial account object.
     * @param {object} newPartialAccountDetailsObject - The partial account object used to perform the update.
     * @param {string|null|undefined} [newPartialAccountDetailsObject.firstName] - The new first name of the account.
     * @param {string|null|undefined} [newPartialAccountDetailsObject.lastName] - The new last name of the account.
     * @param {string|null|undefined} [newPartialAccountDetailsObject.preferredLanguageCode] - The new preferred language code of the account.
     * @returns {Promise<AccountDetails>} - The updated account details.
     * @throws {AccountServiceError} - Thrown if there was an error updating the account.
     */
    async updateAccount(newPartialAccountDetailsObject) {
        const accountDetails = this.cookieManager.getAccountDetails();
        const updatedAccount = { ...accountDetails, ...newPartialAccountDetailsObject };

        ow(updatedAccount, ow.object);
        ow(updatedAccount.firstName, ow.string);
        ow(updatedAccount.lastName, ow.string);

        const languageCode = updatedAccount.preferredLanguageCode;
        const language = getLanguageByLanguageCode(languageCode);
        if (is.nullOrUndefined(language)) {
            const message = `Language code ${languageCode} is invalid`;
            log.debug(message);
            throw new AccountServiceError(message, ErrorCodes.InvalidLanguage);
        }
        const languageKind = mapLanguageToLanguageKindEnum(language);

        const request = new UpdateAccountRequest();
        request.setFirstName(GrpcHelpers.buildStringValue(updatedAccount.firstName));
        request.setLastName(GrpcHelpers.buildStringValue(updatedAccount.lastName));
        request.setPreferredLanguage(languageKind);

        let grpcResponse;
        try {
            grpcResponse = await this.accountsClient.updateAccount(
                request,
                this._getMetadata(true)
            );
        } catch (error) {
            const message = `Error updating the account: ${error}`;
            log.debug(message);
            throw new AccountServiceError(message, ErrorCodes.UpdateAccountError);
        }

        if (is.nullOrUndefined(grpcResponse)) {
            const message = "UpdateAccount response is null or undefined";
            log.debug(message);
            throw new AccountServiceError(message, ErrorCodes.InvalidResponse);
        }

        try {
            this.cookieManager.updateAccountDetails({
                email: updatedAccount.email,
                authToken: updatedAccount.authToken,
                accountId: updatedAccount.accountId,
                firstName: updatedAccount.firstName,
                lastName: updatedAccount.lastName,
                preferredLanguageCode: updatedAccount.preferredLanguageCode,
                isThirdPartyAuth: updatedAccount.isThirdPartyAuth,
                isEmailVerified: updatedAccount.isEmailVerified,
            });
        } catch (error) {
            const errorMessage = `Error updating cookie in updateAccount: ${error}`;
            log.debug(errorMessage);
            throw new AccountServiceError(errorMessage, ErrorCodes.CookieError);
        }

        return updatedAccount;
    }

    /**
     * Updates the password for the account.
     * @param {string} password - the current password for the account.
     * @param {string} newPassword - the new password for the account.
     * @returns {Promise<UpdatePasswordResponse>} Response
     * @throws {AccountServiceError} Thrown if there was an error updating the password.
     */
    async updatePassword(password, newPassword) {
        ow(password, ow.string.nonEmpty);
        ow(newPassword, ow.string.minLength(MINIMUM_PASSWORD_LENGTH));

        if (!window.navigator.onLine) {
            const message = `Error calling updatePassword: Browser not online.`;
            throw new AccountServiceError(message, ErrorCodes.OfflineBrowser);
        }

        const currentEmail = this.cookieManager.getAccountDetails()?.email;
        ow(currentEmail, ow.string.nonEmpty);

        const request = new UpdatePasswordRequest();
        request.setEmail(GrpcHelpers.buildStringValue(currentEmail));
        request.setPassword(GrpcHelpers.buildStringValue(password));
        request.setNewPassword(GrpcHelpers.buildStringValue(newPassword));

        let response;
        try {
            response = await this.accountsClient.updatePassword(request, this._getMetadata(true));
        } catch (error) {
            const message = `Error calling updatePassword: ${error}`;
            log.debug(message);
            throw new AccountServiceError(message, ErrorCodes.UpdatePasswordError);
        }

        if (is.nullOrUndefined(response)) {
            const message = "Error calling updatePassword: response is null or undefined";
            log.debug(message);
            throw new AccountServiceError(message, ErrorCodes.UpdatePasswordError);
        }

        const data = response.toObject();
        if (
            !(
                is.number(data?.accountId) &&
                is.nonEmptyString(data?.authToken) &&
                is.boolean(data?.isAdmin)
            )
        ) {
            const message = "Error calling updatePassword: response data is invalid";
            log.debug(message);
            throw new AccountServiceError(message, ErrorCodes.InvalidResponse);
        }

        try {
            this.cookieManager.updateAccountDetails({
                accountId: data.accountId,
                authToken: data.authToken,
            });
        } catch (error) {
            const errorMessage = `Error updating cookie on updatePassword: ${error}`;
            log.debug(errorMessage);
            throw new AccountServiceError(errorMessage, ErrorCodes.CookieError);
        }

        return {
            accountId: data.accountId,
            authToken: data.authToken,
            isAdmin: data.isAdmin,
        };
    }

    /**
     * Deactivate account
     * @param {string} email - the account email.
     * @param {string} password - the account password.
     * @returns {Promise<void>} Response
     */
    async deactivateAccount(email, password) {
        ow(email, ow.string.nonEmpty);
        ow(password, ow.string.nonEmpty);

        const request = new DeactivateAccountRequest();
        request.setEmail(GrpcHelpers.buildStringValue(email));
        request.setPassword(GrpcHelpers.buildStringValue(password));

        let response;
        try {
            response = await this.accountsClient.deactivateAccount(
                request,
                this._getMetadata(true)
            );
        } catch (error) {
            const errorMessage = `Error calling deactivateAccount: ${error}`;
            log.debug(errorMessage);
            throw new AccountServiceError(errorMessage, ErrorCodes.DeactivateAccountError);
        }

        if (is.nullOrUndefined(response)) {
            const message = "Response is null or undefined";
            log.debug(message);
            throw new AccountServiceError(message, ErrorCodes.InvalidResponse);
        }
    }

    /**
     * Check if the account email is verified.
     * @returns {boolean} Whether the account email is verified or not
     */
    isEmailVerified() {
        const accountDetails = this.cookieManager.getAccountDetails();
        if (is.null_(accountDetails)) {
            log.error("Error validating whether email is verified, Auth cookie is null.");
            return false;
        }

        const isEmailVerified = accountDetails.isEmailVerified ?? false;
        log.debug(`current email verify status: ${isEmailVerified ? "Verified" : "Unverified"}`);

        return isEmailVerified;
    }

    /**
     * Send email verification of the current account.
     * @returns {Promise<void>} Response
     */
    async sendEmailVerification() {
        if (this.isEmailVerified()) {
            const message = "Email is already verified";
            log.debug(message);
            throw new AccountServiceError(message, ErrorCodes.EmailAlreadyVerified);
        }

        let response;
        try {
            response = await this.accountsClient.sendEmailVerification(
                GrpcHelpers.buildEmpty(),
                this._getMetadata(true)
            );
        } catch (error) {
            const errorMessage = `Error calling sendEmailVerification: ${error}`;
            log.debug(errorMessage);
            throw new AccountServiceError(errorMessage, ErrorCodes.SendEmailVerificationError);
        }

        if (is.nullOrUndefined(response)) {
            const message = "Response is null or undefined";
            log.debug(message);
            throw new AccountServiceError(message, ErrorCodes.InvalidResponse);
        }
    }

    /**
     * Verify the account email.
     * @param {string} emailVerificationToken - the verification token.
     * @returns {Promise<void>} Response
     */
    async verifyEmail(emailVerificationToken) {
        ow(emailVerificationToken, ow.string.nonEmpty);

        if (this.isEmailVerified()) {
            const message = "Email is already verified";
            log.debug(message);
            /*
             * If the email is already verified, we return to the caller,
             * as if the call succeeded, as there's no need to verify the
             * user's email address.
             */
            return;
        }

        const request = new VerifyEmailRequest();
        request.setEmailVerificationToken(GrpcHelpers.buildStringValue(emailVerificationToken));

        let response;
        try {
            response = await this.accountsClient.verifyEmail(request, this._getMetadata(true));
        } catch (error) {
            if (error.code === GrpcStatusCodes.statusCodes.notFound) {
                const message = "Not found";
                log.debug(message);
                throw new AccountServiceError(message, ErrorCodes.NotFound);
            } else if (error.code === GrpcStatusCodes.statusCodes.permissionDenied) {
                const message = "Permission denied";
                log.debug(message);
                throw new AccountServiceError(message, ErrorCodes.PermissionDenied);
            } else {
                const message = `Error calling verifyEmail: ${error}`;
                log.debug(message);
                throw new AccountServiceError(message, ErrorCodes.VerifyEmailError);
            }
        }

        if (is.nullOrUndefined(response)) {
            const message = "Response is null or undefined";
            log.debug(message);
            throw new AccountServiceError(message, ErrorCodes.InvalidResponse);
        }

        // Update auth cookie
        try {
            this.cookieManager.updateAccountDetails({
                isEmailVerified: true,
            });
        } catch (error) {
            const message = `Error updating cookie on email verification: ${error}`;
            log.debug(message);
            throw new AccountServiceError(message, ErrorCodes.CookieError);
        }
    }

    /**
     * Performs a forgot password call to AccountService.
     * @param {string} email - the email for the account.
     * @returns {Promise<void>} Response
     */
    async forgotPassword(email) {
        ow(email, ow.string);

        const request = new ForgotPasswordRequest();
        request.setEmail(GrpcHelpers.buildStringValue(email));

        let response;
        try {
            response = await this.accountsClient.forgotPassword(request, this._getMetadata(false));
        } catch (error) {
            const errorMessage = `Error calling forgotPassword: ${error}`;
            log.debug(errorMessage);
            throw new AccountServiceError(errorMessage, ErrorCodes.ForgotPasswordError);
        }

        if (is.nullOrUndefined(response)) {
            const message = "Response is null or undefined";
            log.debug(message);
            throw new AccountServiceError(message, ErrorCodes.InvalidResponse);
        }
    }

    /**
     * Resets the account password.
     * @param {string} resetToken - the reset token.
     * @param {string} password - the account password.
     * @returns {Promise<void>} Response
     */
    async resetPassword(resetToken, password) {
        ow(resetToken, ow.string);
        ow(password, ow.string);

        const request = new ResetPasswordRequest();
        request.setResetToken(GrpcHelpers.buildStringValue(resetToken));
        request.setPassword(GrpcHelpers.buildStringValue(password));

        let response;
        try {
            response = await this.accountsClient.resetPassword(request, this._getMetadata(false));
        } catch (error) {
            const errorMessage = `Error calling resetPassword: ${error}`;
            log.debug(errorMessage);
            throw new AccountServiceError(errorMessage, ErrorCodes.ResetPasswordError);
        }

        if (is.nullOrUndefined(response)) {
            const message = "Response is null or undefined";
            log.debug(message);
            throw new AccountServiceError(message, ErrorCodes.InvalidResponse);
        }
    }

    /**
     * Updates the account email.
     * @param {string} password - the password for the account.
     * @param {string} newEmail - the email to change to.
     * @returns {Promise<UpdateEmailResponse>} Response
     * @throws {AccountServiceError} Thrown if there was an error updating the email.
     */
    async updateEmail(password, newEmail) {
        ow(password, ow.string.nonEmpty);
        ow(newEmail, ow.string.nonEmpty);

        const currentEmail = this.cookieManager.getAccountDetails()?.email;
        ow(currentEmail, ow.string.nonEmpty);

        const request = new UpdateEmailRequest();
        request.setEmail(GrpcHelpers.buildStringValue(currentEmail));
        request.setPassword(GrpcHelpers.buildStringValue(password));
        request.setNewEmail(GrpcHelpers.buildStringValue(newEmail));

        let response;
        try {
            response = await this.accountsClient.updateEmail(request, this._getMetadata(true));
        } catch (error) {
            const message = `Error calling updateEmail: ${error}`;
            log.debug(message);
            throw new AccountServiceError(message, ErrorCodes.UpdateEmailError);
        }

        if (is.nullOrUndefined(response)) {
            const message = "UpdateEmail response is null or undefined";
            log.debug(message);
            throw new AccountServiceError(message, ErrorCodes.InvalidResponse);
        }

        const data = response.toObject();
        if (
            !(
                is.number(data?.accountId) &&
                is.nonEmptyString(data?.authToken) &&
                is.boolean(data?.isAdmin)
            )
        ) {
            const message = "Error calling updateEmail: response data is invalid";
            log.debug(message);
            throw new AccountServiceError(message, ErrorCodes.InvalidResponse);
        }

        try {
            this.cookieManager.updateAccountDetails({
                email: newEmail,
                authToken: data.authToken,
                accountId: data.accountId,
                isEmailVerified: false,
            });
        } catch (error) {
            const errorMessage = `Error updating account details cookie on updateEmail: ${error}`;
            log.debug(errorMessage);
            throw new AccountServiceError(errorMessage, ErrorCodes.CookieError);
        }

        return {
            ...data,
            email: newEmail,
        };
    }

    /**
     * Tracks user's account details
     * @param {AccountDetails} userDetails - userDetails
     * @returns {void} - void
     * @private
     */
    _trackUserDetails(userDetails) {
        try {
            this.userTrackingClient.identifyUser({
                accountId: userDetails.accountId,
                email: userDetails.email,
                firstName: userDetails.firstName,
                lastName: userDetails.lastName,
                preferredLanguage: userDetails.preferredLanguageCode,
            });
        } catch (error) {
            // Don't throw an error since the app should still be functional
            log.debug(`Error identifying the user: ${error}`);
        }
    }

    /**
     * Clears account details
     * @returns {void} - void
     * @private
     */
    _clearAccount() {
        try {
            this.cookieManager.removeAccountDetails();
            localStorage.clear();
        } catch (error) {
            log.debug(`Error clearing account details: ${error}`);
            throw new AccountServiceError(
                "Error clearing account details",
                ErrorCodes.ClearAccountError
            );
        }
    }

    /**
     * Gets metadata object that may include headers depending on the includeAuthHeaders value provided.
     * @param {boolean} [includeAuthHeaders = false] - value indicating whether the returned object should include auth headers.
     * @returns {object} - empty object or object with authorization header.
     */
    _getMetadata(includeAuthHeaders = false) {
        ow(includeAuthHeaders, ow.boolean);

        const metadata = {};
        if (includeAuthHeaders === true) {
            const accountDetails = this.cookieManager.getAccountDetails();
            if (is.nonEmptyString(accountDetails?.authToken)) {
                metadata[
                    questUIStrings.httpHeaders.authorization
                ] = `${questUIStrings.bearer} ${accountDetails.authToken}`;
            } else {
                /*
                 * This should not happen if authentication is handled correctly, but
                 * if it does, return the metadata without the auth headers and let the call fail.
                 */
                log.debug("Error getting auth token. Auth token is null or undefined");
            }
        }
        return metadata;
    }

    async _trackReferral(referrer) {
        try {
            const QuestServiceClient = (
                await import("../../clients/QuestService/QuestServiceClient")
            ).default;
            await QuestServiceClient.trackReferral(referrer);
        } catch (error) {
            log.debug(`Error tracking the referral from the referrer ${referrer}:`, error);
        }
    }
}

export default new AccountService(accountsClient, cookieManager, userTrackingClient);

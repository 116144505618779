import { assign, createMachine } from "xstate";
import { logState } from "../../clients/XState/utils";
import accountService from "../AccountService";

const MACHINE_ID = "accountMachine";

const accountMachineStates = {
    bootingUp: "bootingUp",
    idle: "idle",
    updatingName: "updatingName",
    sendingUpdateNameRequest: "sendingUpdateNameRequest",
};

const accountMachineServices = {
    updateName: "updateName",
};

export const errorConditions = {
    updateNameFailed: "Update name request failed",
};

/**
 * Builds the account machine definition
 * @returns {object} - The account machine definition
 */
const accountMachine = createMachine(
    {
        id: MACHINE_ID,
        initial: accountMachineStates.bootingUp,
        predictableActionArguments: true,
        context: {
            currentErrorCondition: null,
            wasNameUpdateSuccessful: false,
        },
        on: {
            GO_TO_IDLE_STATE: {
                target: accountMachineStates.idle,
            },
            START_UPDATING_NAME: {
                target: accountMachineStates.updatingName,
            },
        },
        states: {
            bootingUp: {
                entry: logState(MACHINE_ID, accountMachineStates.bootingUp),
                always: { target: accountMachineStates.idle },
            },
            idle: {
                entry: [
                    logState(MACHINE_ID, accountMachineStates.idle),
                    assign({
                        currentErrorCondition: null,
                    }),
                ],
            },
            updatingName: {
                entry: [
                    logState(MACHINE_ID, accountMachineStates.updatingName),
                    assign({ wasNameUpdateSuccessful: false }),
                ],
                on: {
                    SEND_UPDATED_NAME: {
                        target: accountMachineStates.sendingUpdateNameRequest,
                    },
                },
            },
            sendingUpdateNameRequest: {
                entry: logState(MACHINE_ID, accountMachineStates.sendingUpdateNameRequest),
                invoke: {
                    id: accountMachineServices.updateName,
                    src: "updateAccount",
                    onDone: {
                        target: accountMachineStates.idle,
                        actions: [
                            assign({
                                wasNameUpdateSuccessful: true,
                            }),
                        ],
                    },
                    onError: {
                        target: accountMachineStates.updatingName,
                        actions: [
                            assign({
                                currentErrorCondition: errorConditions.updateNameFailed,
                            }),
                        ],
                    },
                },
            },
        },
    },
    {
        services: {
            updateAccount: (_, event) => {
                const updateObject = {};
                if (event.firstName && event.lastName) {
                    updateObject.firstName = event.firstName;
                    updateObject.lastName = event.lastName;
                }
                if (event.preferredLanguageCode) {
                    updateObject.preferredLanguageCode = event.preferredLanguageCode;
                }

                return accountService.updateAccount(updateObject);
            },
        },
    }
);

export { accountMachine, accountMachineStates };

import { useContext } from "react";
import ow from "ow";
import useBeacon from "../../../hooks/useBeacon";
import AccountContext from "../AccountContext";

/** @typedef {import("../AccountContext").AccountContext} AccountContext */

/**
 * useAccount - Exposes the Account context.
 * @returns {AccountContext} - Account context.
 */
const useAccount = () => {
    const { suggestArticle } = useBeacon();

    const context = useContext(AccountContext);

    suggestArticle(context);
    ow(context, ow.object);

    return context;
};

export default useAccount;

import { useLocation } from "react-router-dom";
import { termRoutes } from "../constants/globalRoutes";
import referrerSources from "../constants/referrerSources";
import questUIStrings from "../constants/strings";

const customLayoutRoutes = [termRoutes.termsOfService, termRoutes.privacyPolicy];

/**
 * Boolean that indicates whether a custom layout should be used
 * @returns {boolean} custom layout boolean
 */
const useIsCustomLayout = () => {
    const { pathname } = useLocation();

    return (
        pathname.includes(referrerSources.cccba) ||
        pathname.includes(questUIStrings.peopleClerk) ||
        customLayoutRoutes.includes(pathname.toLowerCase())
    );
};

export default useIsCustomLayout;

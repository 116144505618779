import React, { useState, useEffect, useMemo, useCallback } from "react";
import strings from "../../constants/strings";
import EmailVerificationContext from "./EmailVerificationContext";
import useGetVerificationTokenQueryString from "./hooks/useGetVerificationTokenQueryString";

/**
 * @typedef EmailVerificationProviderProps
 * @property {React.ReactElement} children - Children.
 */

/**
 * EmailVerificationProvider
 * @param {EmailVerificationProviderProps} props - EmailVerificationProvider props.
 * @returns {React.ReactElement} - EmailVerificationProvider component.
 */
const EmailVerificationProvider = ({ children }) => {
    const [verificationToken, setVerificationToken] = useState(null);

    const verificationTokenQS = useGetVerificationTokenQueryString();

    const clearVerificationToken = useCallback(() => {
        setVerificationToken(null);
        sessionStorage.removeItem(strings.emailVerificationToken);
    }, []);

    useEffect(() => {
        if (verificationTokenQS) {
            setVerificationToken(verificationTokenQS);
            sessionStorage.setItem(strings.emailVerificationToken, verificationTokenQS);
        } else {
            const sessionStorageVerificationToken = sessionStorage.getItem(
                strings.emailVerificationToken
            );
            if (sessionStorageVerificationToken) {
                setVerificationToken(sessionStorageVerificationToken);
            }
        }
    }, [verificationTokenQS]);

    const value = useMemo(
        () => ({ verificationToken, clearVerificationToken }),
        [verificationToken, clearVerificationToken]
    );

    return (
        <EmailVerificationContext.Provider value={value}>
            {children}
        </EmailVerificationContext.Provider>
    );
};

export default EmailVerificationProvider;

import React, { useEffect, useRef, useState } from "react";
import is from "@sindresorhus/is";
import { useUnleashContext } from "@unleash/proxy-client-react";
import log from "loglevel";
import { useLocation, useNavigate } from "react-router-dom";
import userTrackingClient from "./clients/UserTracking";
import { authRoutes } from "./constants/globalRoutes";
import { NodeEnvironmentKind } from "./constants/nodeEnvironmentKind";
import questUIStrings from "./constants/strings";
import useIsCustomLayout from "./hooks/useIsCustomLayout";
import useAccount from "./provider/account/hooks/useAccount";
import useAuth from "./provider/auth/hooks/useAuth";
import cookieManager from "./services/cookieManager";
import sessionStorageKeys from "./sessionStorageKeys";

// Lazy load auth vs unauthenticated app to improve load performance.
const AuthenticatedApp = React.lazy(() => import("./components/CasePlan/App/AuthenticatedApp"));
const UnauthenticatedApp = React.lazy(() => import("./components/CasePlan/App/UnauthenticatedApp"));

// Preload dev tools (to prevent race condition documented in https://q4justice.atlassian.net/browse/MVP-8482.
if (process.env.NODE_ENV === NodeEnvironmentKind.Development) {
    import(/* webpackPreload */ "./components/Development");
}

const App = () => {
    const { isLoggedIn, isValidating } = useAuth();
    const { wasNameUpdateSuccessful } = useAccount();
    const [accountDetails, setAccountDetails] = useState(null);

    const updateContext = useUnleashContext();

    const navigate = useNavigate();
    const location = useLocation();
    const initialURL = useRef(location);

    // Get location and save URL param to sessionStorage
    const query = new URLSearchParams(initialURL?.current?.search);
    const referrerValue = query.get(questUIStrings.urlParams.referrer);
    window.sessionStorage.setItem(sessionStorageKeys.referrer, referrerValue);

    const isCustomLayout = useIsCustomLayout();

    useEffect(() => {
        if (isLoggedIn) {
            const accountDetails = cookieManager.getAccountDetails();
            updateContext({ userId: accountDetails?.email });
            setAccountDetails(accountDetails);
        } else {
            updateContext({ userId: null });
            setAccountDetails(null);
        }
    }, [isLoggedIn, location.pathname, location.search, wasNameUpdateSuccessful, updateContext]);

    useEffect(() => {
        // If user data is missing, then navigate to the page to get user details
        if (
            isLoggedIn &&
            location.pathname !== authRoutes.registerAccountDetails &&
            accountDetails &&
            (!accountDetails.firstName || !accountDetails.lastName)
        ) {
            navigate(authRoutes.registerAccountDetails);
        }
    }, [accountDetails, isLoggedIn, location.pathname, navigate]);

    useEffect(() => {
        if (isLoggedIn) {
            const accountDetails = cookieManager.getAccountDetails();
            userTrackingClient.identifyUser({
                accountId: accountDetails.accountId,
                email: accountDetails.email,
                firstName: accountDetails.firstName,
                lastName: accountDetails.lastName,
                preferredLanguage: accountDetails.preferredLanguageCode,
            });
        }
    }, [isLoggedIn, location.pathname]);

    if (isValidating) {
        return null;
    }

    if (
        isLoggedIn &&
        (!is.number(accountDetails?.accountId) || !is.nonEmptyString(accountDetails?.email))
    ) {
        log.debug("accountId and email should be set if user is logged in");
        return null;
    }

    if (!isLoggedIn) {
        return <UnauthenticatedApp isCustomLayout={isCustomLayout} />;
    }

    return <AuthenticatedApp accountDetails={accountDetails} />;
};

export default App;

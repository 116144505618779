import React from "react";
import { useMachine } from "@xstate/react";
import ow from "ow";
import accountMachine, { accountMachineStates } from "../../services/account";
import AccountContext from "./AccountContext";

/**
 * @typedef AccountProviderProps
 * @property {React.ReactElement} children - Children.
 */

/**
 * AccountProvider
 * @param {AccountProviderProps} props - AccountProvider props.
 * @returns {React.ReactElement} - AccountProvider component.
 */
const AccountProvider = ({ children }) => {
    ow(children, ow.optional.object);
    const [accountMachineState, accountMachineSend] = useMachine(accountMachine);

    const isNameDialogOpen = [
        accountMachineStates.sendingUpdateNameRequest,
        accountMachineStates.updatingName,
    ].includes(accountMachineState.value);

    const currentErrorCondition = accountMachineState.context.currentErrorCondition;
    const wasNameUpdateSuccessful = accountMachineState.context.wasNameUpdateSuccessful;

    return (
        <AccountContext.Provider
            value={{
                accountMachineState,
                accountMachineSend,
                currentErrorCondition,
                isNameDialogOpen,
                wasNameUpdateSuccessful,
                events: {
                    startUpdatingName: "START_UPDATING_NAME",
                    sendUpdatedName: "SEND_UPDATED_NAME",
                    goToIdleState: "GO_TO_IDLE_STATE",
                },
            }}
        >
            {children}
        </AccountContext.Provider>
    );
};

export default AccountProvider;

import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import strings from "../../../constants/strings";

/**
 * useGetVerificationTokenQueryString - Retrieves the email verification token from the URL params and removes it.
 * @returns {string} - Email verification token.
 */
const useGetVerificationTokenQueryString = () => {
    const [value, setValue] = useState(null);

    const [urlParams, setUrlParams] = useSearchParams(strings.emailVerificationToken);

    useEffect(() => {
        if (!urlParams.has(strings.emailVerificationToken)) {
            return;
        }
        const token = urlParams.get(strings.emailVerificationToken);
        setValue(token);
        urlParams.delete(strings.emailVerificationToken);
        setUrlParams(urlParams);
    }, [urlParams, setUrlParams]);

    return value;
};

export default useGetVerificationTokenQueryString;
